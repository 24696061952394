import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';

const GreyWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
`;

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 60px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 40%;
    text-align: left;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const HeroLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 85px 0;

  > div {
    margin: 0 20px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 40px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 300px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 20px 20px 10px 20px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
`;

const StandardContainer = styled.div`
  text-align: center;
  margin: 60px auto;
  width: 90%;
`;

const ShadowBox = styled.div`
  padding: 40px 50px;
  margin: 20px 0;
  border-radius: 12px;
  box-shadow: 0 0 4px 1px ${({ theme: { colors } }) => colors.boxshadowmedium};
  border: solid 1px ${({ theme: { colors } }) => colors.grey20};

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 50%;
    margin: 0 20px;
    align-self: stretch;
  }
`;

const ShadowBoxLogoArea = styled.div`
  margin-bottom: 30px;
`;

const ShadowBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    return (
      <Body header={header}>
        <SEO
          title="Rotaready and CPL Learning Offer"
          description="20% off your first year with Rotaready when purchased with Access CPL Learning!"
          url="landing/cpl-offer"
        />

        <ResponsiveContainer>
          <HeroLogos>
            <StaticImage
              src="../../../images/landing/cpl-offer/rotaready-logo.webp"
              height={60}
              alt="Rotaready logo"
            />

            <StaticImage
              src="../../../images/landing/cpl-offer/cpl-logo.webp"
              height={42}
              alt="CPL Learning logo"
            />
          </HeroLogos>
        </ResponsiveContainer>

        <GreyWrapper>
          <ResponsiveContainer>
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <HeroTextWrapper>
                    <H1 uistyle="brand160" text="20% off your first year with Rotaready when purchased with Access CPL Learning!" />
                  </HeroTextWrapper>

                  <HeroTextWrapper>
                    <H4 uistyle="grey">We're running a limited time offer in partnership with learning and development solution, Access CPL Learning. Seamlessly connected with our employee scheduling solution to enable you to onboard, train & schedule your employees in one streamlined process. Sign up before 30th June 2024 and get 20% off your first year with both solutions*</H4>
                  </HeroTextWrapper>

                  <CtaButtons>
                    <a href="https://www.theaccessgroup.com/en-gb/hospitality/form/rotaready/cpl-rotaready-20/?utm_source=rotaready_website&utm_medium=search_ad&utm_campaign=rotaready_CPL_salesplay&utm_content=Rotaready_website_get_the_offer" target="_blank" rel="noopener noreferrer">
                      <Button uistyle="primary" text="Get the offer" size="lg"/>
                    </a>

                    <a href="https://pages.theaccessgroup.com/HOS1-RR-Intergrated-Staff-Scheduling.html?utm_source=rotaready_website&utm_medium=search_ad&utm_campaign=rotaready_CPL_salesplay&utm_content=Rotaready_website_get_the_offer" target="_blank" rel="noopener noreferrer">
                      <Button uistyle="primary" text="Watch the integration video" ghost borderless size="lg"/>
                    </a>
                  </CtaButtons>
                </HeroBody>

                <HeroIllustration>
                  <StaticImage
                    src="../../../images/landing/cpl-offer/rotaready-cpl-user-interface.webp"
                    height={460}
                    alt="Rotaready app and CPL user interface"
                  />
                </HeroIllustration>
              </HeroInnerContainer>
            </Hero>
          </ResponsiveContainer>
        </GreyWrapper>

        <ResponsiveContainer>
          <ShadowBoxContainer>
            <ShadowBox>
              <ShadowBoxLogoArea>
                <StaticImage
                  src="../../../images/landing/cpl-offer/rotaready-logo.webp"
                  height={40}
                  alt="Rotaready logo"
                />
              </ShadowBoxLogoArea>

              <TextBlock>
                <Text size="lg" text="Rotaready, an Access company, is a workforce management solution built specifically for hospitality businesses. The staff scheduling software helps operators effortlessly schedule rotas, optimise wage spending, record attendance and approve timesheets for payroll." />
              </TextBlock>

              <TextBlock>
                <Text size="lg" text="Whether you're a single or multi-site operator, Rotaready takes the stress out of staff scheduling and enables you to build rotas that are perfectly aligned with demand." />
              </TextBlock>

              <TextBlock>
                <UL size="lg" uistyle="brand160" iconUistyle="primary">
                  <TextListItem text="Save up to 5% on labour costs" />
                  <TextListItem text="Reduce admin time by as much as 80%" />
                  <TextListItem text="Average ROI of 620%" />
                </UL>
              </TextBlock>
            </ShadowBox>

            <ShadowBox>
              <ShadowBoxLogoArea>
                <StaticImage
                  src="../../../images/landing/cpl-offer/cpl-logo.webp"
                  height={32}
                  alt="CPL Learning logo"
                />
              </ShadowBoxLogoArea>

              <TextBlock>
                <Text size="lg" text="Access CPL Learning are learning and development experts. Their industry leading LMS delivers a personalised learning experience that empowers hospitality team members and puts them in control of their personal development." />
              </TextBlock>

              <TextBlock>
                <Text size="lg" text="Their approach to learning content is to provide a mix of inspiring and relevant e-learning courses, microlearning resources, and development tools alongside all the critical compliance training and reporting requirements. Engaging learners and ensuring hospitality operators achieve their goals." />
              </TextBlock>
            </ShadowBox>
          </ShadowBoxContainer>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <StandardContainer>
            <TextBlock>
              <H1 uistyle="brand160" text="Connected employee scheduling & training" />
            </TextBlock>
          </StandardContainer>

          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <Text size="xl" text="The seamless connection between our employee scheduling solutions and Access CPL Learning, enables you to effortlessly onboard, train & schedule your employees in one streamlined process. Helping you improve efficiency, deliver a faster employee time to value and an all round better employee experience!" />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.formTopRef}>
              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="brand160" iconUistyle="primary">
                    <TextListItem text="Eliminate duplication of work" />
                    <TextListItem text="Guarantee accuracy across solutions" />
                    <TextListItem text="Ensure employees are always trained correctly, maintaining compliance" />
                    <TextListItem text="Streamline employee offboarding" />
                    <TextListItem text="Perfect the employee experience" />
                  </UL>
                </TextBlock>
              </BenefitBlock>
            </BodyItem>
          </BodyWrapper>

          <StandardContainer>
            <CtaButtons>
              <a
                href="https://www.theaccessgroup.com/en-gb/hospitality/form/rotaready/cpl-rotaready-20/?utm_source=rotaready_website&utm_medium=search_ad&utm_campaign=rotaready_CPL_salesplay&utm_content=Rotaready_website_find_out_more"
                target="_blank" rel="noopener noreferrer">
                <Button uistyle="primary" text="Find out more" size="lg"/>
              </a>

              <a
                href="https://pages.theaccessgroup.com/HOS1_rotaready_Offer_TCs.html"
                target="_blank" rel="noopener noreferrer">
                <Button uistyle="primary" text="Terms and conditions" ghost borderless size="lg"/>
              </a>
            </CtaButtons>

            <TextBlock>
              <Text size="xl" uistyle="grey"
                    text="*Offer only available on minimum contact term. Terms and conditions apply"/>
            </TextBlock>
          </StandardContainer>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default Page;
